let fetchDailyStateData = async () => {
  let stateDailyData = {};

  await fetch(
    "https://api.rootnet.in/covid19-in/unofficial/covid19india.org/statewise/history.json"
  )
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      //console.log(res.data.history);
      let days = res.data.history.length;
      let offset = days - 31;
      let stateNames = [];

      if (offset <= 0) {
        offset = 1;
      }

      res.data.history[0].statewise.forEach((item) => {
        stateNames.push(item.state);
        stateDailyData[item.state] = {
          totalData: [],
          dailyRiseData: [],
        };
      });
      //console.log(offset);
      //if()
      let counter = 1;
      let tmp = 0;

      let prevItem = {};

      res.data.history.map((item) => {
        if (counter < offset) {
          tmp = 0;
        }
        if (counter >= offset || counter === days) {
          item.statewise.forEach((statewisedata) => {
            //stateDailyData[statewisedata.state.toString()].stateData.push({
            //...statewisedata,
            //day: item.day
            //});
            if (stateDailyData[statewisedata.state.toString()] !== undefined) {
              //console.log(stateDailyData[statewisedata.state.toString()].stateData);
              stateDailyData[statewisedata.state.toString()].totalData.push({
                ...statewisedata,
                day: item.day,
              });
              if (tmp > 1) {
                let totaldataArrLength =
                  stateDailyData[statewisedata.state.toString()].totalData
                    .length;
                let dailyConfirmed =
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 1
                  ].confirmed -
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 2
                  ].confirmed;
                let dailyActive =
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 1
                  ].active -
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 2
                  ].active;
                let dailyRecovered =
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 1
                  ].recovered -
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 2
                  ].recovered;
                let dailyDeaths =
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 1
                  ].deaths -
                  stateDailyData[statewisedata.state.toString()].totalData[
                    totaldataArrLength - 2
                  ].deaths;
                stateDailyData[
                  statewisedata.state.toString()
                ].dailyRiseData.push({
                  day: item.day,
                  confirmed: dailyConfirmed >= 0 ? dailyConfirmed : 0,
                  active: dailyActive,
                  recovered: dailyRecovered >= 0 ? dailyRecovered : 0,
                  deaths: dailyDeaths >= 0 ? dailyDeaths : 0,
                });
              }
            }
          });
        }
        counter += 1;
        tmp += 1;

        prevItem = item;
      });
      //console.log(stateDailyData);
    })
    .catch((err) => console.log(err));

  return stateDailyData;
};

export default fetchDailyStateData;
