import React, { Component } from "react";

class StatsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataLoaded: false,
    };
  }

  displayStats = () => {
    if (this.state.allDataLoaded === true) {
      return (
        <div className="stats-container">
          <div className="stat">
            <h4 className="stat__title">Confirmed</h4>
            <div className="stat-inliner">
              <p
                className="stat__figure small"
                style={{ color: "rgb(88,81,219)" }}
              >
                {this.props.stats.confirmed
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <span
                className="stat__daily-rise"
                style={{ color: "rgb(88,81,219)" }}
              >
                {this.props.stats.confirmedDaily}
              </span>
            </div>
          </div>

          <div className="stat">
            <h4 className="stat__title">Active</h4>
            <div className="stat-inliner">
              <p className="stat__figure small" style={{ color: "#007bff" }}>
                {this.props.stats.active
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <span className="stat__daily-rise" style={{ color: "#007bff" }}>
                {this.props.stats.activeDaily}
              </span>
            </div>
          </div>

          <div className="stat">
            <h4 className="stat__title">Recovered</h4>
            <div className="stat-inliner">
              <p className="stat__figure small" style={{ color: "#4ccf76" }}>
                {this.props.stats.recovered
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <span className="stat__daily-rise" style={{ color: "#4ccf76" }}>
                {this.props.stats.recoveredDaily}
              </span>
            </div>
          </div>

          <div className="stat">
            <h4 className="stat__title">Deaths</h4>
            <div className="stat-inliner">
              <p className="stat__figure small" style={{ color: "#e1306c" }}>
                {this.props.stats.dead
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <span className="stat__daily-rise" style={{ color: "#e1306c" }}>
                {this.props.stats.deadDaily}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      prevProps.stats.allDataLoaded !== this.props.stats.allDataLoaded &&
      this.props.stats.allDataLoaded !== false
    ) {
      return true;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        allDataLoaded: true,
      });
    }
  }

  render() {
    return this.displayStats();
  }
}

export default StatsContainer;
