import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import ConvertDate from "./convertDate";

class ActiveChartGoa extends Component {
  constructor(props) {
    super(props);
    //this.value();
  }

  state = {
    chartDataGoa: {
      // All dates should come down. Take weekly periods. DYNAMIC
      labels: ["10th March", "13th March", "16th March", "today"],
      datasets: [
        {
          label: "Active",
          data: [0, 2, 2],
          borderColor: "rgb(0, 123, 255)",
          backgroundColor: "rgba(0, 123, 255, 0.1)",
        },
      ],
    },
  };

  //NEW CODE USING PROPS
  static getDerivedStateFromProps(nextProps, prevState) {
    let res = nextProps.stateDailyData;

    //console.log(res);
    if (res != undefined || res != null) {
      if (Object.keys(res).length) {
        //console.log("inside inf: ", res);

        let dataActive = []; //Y axis data
        let labels = []; //labels for the X axis
        let counter = 0; //counter for counting days iterated through
        let days = 0; //number of days

        let offset = 0; //Used to mark the start of the 30 datapoints to plot. Updated later in code
        let pointsPlotted = 0; //counter to keep track of points plotted

        counter = 1;
        pointsPlotted = 0;

        //console.log("test: ", res.totalData);
        days = res.totalData.length; //total number of days given by the API

        offset = days - 30; //basically show only 30 datapoints at a time on the graph

        let daysTrimmed = 30; //30 days

        //These are the 2 labels which will be shown on X axis
        let midLabelLow = Math.round(daysTrimmed * (1 / 3));
        let midLabelHigh = Math.round(daysTrimmed * (2 / 3));

        var tmp = 0; //keeps track of the number of points plotted
        res.totalData.map((item) => {
          //tmp = 0;
          if (counter < offset) {
            tmp = 0;
          }
          if (counter >= offset || counter === days) {
            if (item.active === "") {
              //if today's cases are given as null (if they didn't update today's cases) then show previous day data instead of today
              if (counter === days) {
                labels[labels.length - 1] = ConvertDate(
                  res.totalData[days - 2].day
                );
                return; //like a break from the loop
              } else {
                dataActive.push(res.totalData[counter - 2].active);
              }
            } else {
              dataActive.push(item.active);
            }

            if (
              counter === offset ||
              tmp === midLabelLow ||
              tmp === midLabelHigh
            ) {
              labels.push(ConvertDate(item.day));
            } else if (counter === days) {
              labels.push(ConvertDate(item.day));
              //console.log('reached today');
            } else {
              labels.push("");
            }
            pointsPlotted += 1;
          }
          counter += 1;
          tmp += 1;
        });

        //EQUIVALENT TO this.setState()
        return {
          chartDataGoa: {
            // All dates should come down. Take weekly periods. DYNAMIC
            labels: labels,
            datasets: [
              {
                label: "Active",
                data: dataActive,
                lineTension: 0.2,
                borderColor: "rgb(0, 123, 255)",
                backgroundColor: "rgba(0, 123, 255, 0.1)",
              },
            ],
          },
        };
      }
      return null;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        className="chart__graph-container active-chart"
        style={{ display: "none" }}
      >
        <Line
          data={this.state.chartDataGoa}
          options={{
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    stepSize: 250,
                  },
                },
              ],
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
    );
  }
}

export default ActiveChartGoa;
