import React, { Component } from "react";

class DistrictTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCounter: 1,
      sortTypeArr: [
        "Alphabetically",
        "Highest Confirmed",
        "Highest Active",
        "Highest Recovered",
        "Highest Deaths",
      ],
      districtData: {},
    };
  }
  addToTableSort = (elements) => {
    let tab = document.getElementById("district-table-body");

    while (tab.firstChild) {
      tab.removeChild(tab.firstChild);
    }

    let rank = 1;
    //console.log(elements, this.state.sortCounter);
    elements.forEach((element, index) => {
      //console.log(element.district, index);
      let tr = document.createElement("tr");
      //let td0 = document.createElement('td');
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      let td3 = document.createElement("td");
      let td4 = document.createElement("td");
      let td5 = document.createElement("td");

      //td0.innerHTML = rank.toString();
      td1.innerHTML = element["district"];
      td2.innerHTML = element["confirmed"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td3.innerHTML =
        element["active"] < 0
          ? 0
          : element["active"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td4.innerHTML = element["recovered"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td5.innerHTML = element["dead"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //tr.appendChild(td0);
      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);
      tr.appendChild(td5);

      tab.appendChild(tr);
      rank += 1;
    });
  };

  addToTable = (elements) => {
    let tab = document.getElementById("district-table-body");

    while (tab.firstChild) {
      tab.removeChild(tab.firstChild);
    }

    let rank = 1;
    for (let element in elements) {
      let tr = document.createElement("tr");
      //let td0 = document.createElement('td');
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      let td3 = document.createElement("td");
      let td4 = document.createElement("td");
      let td5 = document.createElement("td");

      //td0.innerHTML = rank.toString();
      td1.innerHTML = element;
      td2.innerHTML = elements[element]["confirmed"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td3.innerHTML =
        elements[element]["active"] < 0
          ? 0
          : elements[element]["active"]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td4.innerHTML = elements[element]["recovered"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td5.innerHTML = elements[element]["deceased"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //tr.appendChild(td0);
      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);
      tr.appendChild(td5);

      tab.appendChild(tr);
      rank += 1;
    }
  };

  sortClicked = () => {
    let districtData = this.state.stateDistricts;
    let arr = [];

    for (let key in districtData) {
      let obj = {};
      obj["confirmed"] = districtData[key]["confirmed"];
      obj["recovered"] = districtData[key]["recovered"];
      obj["dead"] = districtData[key]["deceased"];
      obj["active"] = districtData[key]["active"];
      obj["district"] = key;
      arr.push(obj);
    }

    if (this.state.sortCounter === 0) {
      this.setState({
        sortCounter:
          (this.state.sortCounter + 1) % this.state.sortTypeArr.length,
      });
      this.addToTable(districtData);
    } else {
      if (this.state.sortCounter === 1) {
        arr.sort(function (a, b) {
          return b.confirmed - a.confirmed;
        });
      }
      if (this.state.sortCounter === 2) {
        arr.sort(function (a, b) {
          return b.active - a.active;
        });
      }
      if (this.state.sortCounter === 3) {
        arr.sort(function (a, b) {
          return b.recovered - a.recovered;
        });
      }
      if (this.state.sortCounter === 4) {
        arr.sort(function (a, b) {
          return b.dead - a.dead;
        });
      }
      this.setState({
        sortCounter:
          (this.state.sortCounter + 1) % this.state.sortTypeArr.length,
      });
      this.addToTableSort(arr);
    }
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.stateDistricts !== this.props.stateDistricts) {
      return true;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.addToTable(this.props.stateDistricts);
      this.setState({
        stateDistricts: this.props.stateDistricts,
      });
    }
  }

  render() {
    return (
      <div className="state state-wise-data district">
        <h3 className="state__title">District wise data</h3>
        <div className="state__sort-button" onClick={this.sortClicked}>
          Sorted:{" "}
          {
            this.state.sortTypeArr[
            this.state.sortCounter === 0 ? 4 : this.state.sortCounter - 1
            ]
          }
        </div>
        <div className="state__table-container">
          <table>
            <thead>
              <tr>
                <th>Dist</th>
                <th>Cnfrm</th>
                <th>Actve</th>
                <th>Rcvrd</th>
                <th>Dead</th>
              </tr>
            </thead>

            <tbody id="district-table-body"></tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DistrictTable;
