import React, { Component } from "react";
import {Event, initGA} from '../ga-tracking';

class WhatsAppShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waText: "",
    };
  }

  componentDidMount(){
    initGA('UA-158859238-1', {titleCase: false});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.whatsappText !== "" ||
        nextProps.whatsappText != null ||
        nextProps.whatsappText != undefined) &&
      document.getElementById("whatsAppDataShareButton") !== null
    ) {
      document.getElementById("whatsAppDataShareButton").href =
        nextProps.whatsappText;
      return {
        waText: nextProps.whatsappText,
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="share-module">
        <div className="share-module__title">
          Share Covid-19 update on 
        </div>
        <a
          id="whatsAppDataShareButton"
          href="#"
          style={{ textDecoration: "none" }}
          target="_blank"
          //onClick={ga('send', 'event', 'WhatsAppButton', 'Click', 'WhatsAppUpdatesShare')}
          // onClick={() => {
          //   Event('WhatsAppButton', 'ClickWhatsApp', 'WhatsAppUpdatesShare');
          // }}
        >
          <div
            className={`button-location ${
              this.state.waText === "" ? "loading" : ""
            }`} style={{backgroundColor:'#25d366'}}
          >
            WhatsApp
          </div>
        </a>

        {/* <a id="whatsAppDataShareButton" href="#"><div className="button-location">Share On WhatsApp</div></a> */}
      </div>
    );
  }
}

export default WhatsAppShare;
