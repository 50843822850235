import React, { Component } from "react";

let mapsection = (props) => {
  let heatmapModal = () => {
    document.getElementsByClassName("confirmedModal")[0].style.display = "flex";
  };

  let closeheatmapModal = () => {
    document.getElementsByClassName("confirmedModal")[0].style.display = "none";
  };

  return (
    <div>
      <div className="confirmedModal">
        <div className="modal">
          <button onClick={closeheatmapModal}>&#10006;</button>
          <h3 className="modal__title">Understanding the heat map</h3>
          <p>
            The heat map shows the locations of Health Centres (PHCs, UHCs,
            CHCs)* where the cases are 'located'.
          </p>
          <p>
            The health department has stopped releasing city wise data and is
            now sharing Health Centre wise data.
          </p>
        </div>
      </div>

      <div className="map-container stat">
        <h4 className="state__title">Track Covid-19 cases in Goa</h4>



        <p className="note__healthcentre">
          * UHC: Urban Health Centre <br />
          {"  "} &nbsp;&nbsp; PHC: Public Health Centre <br />
          {"  "} &nbsp;&nbsp; CHC: Community Health Centre
        </p>

        <span className="heatmap-modal-link" onClick={heatmapModal}>




          <i>
            <strong>Understand the heat map </strong>
          </i>
        </span>{" "}


        <div className="map">
          <div className="map__iframe-container" id="iframeContainer">
            <iframe
              title="Goa-Corona-Dark"
              src="https://www.arcgis.com/apps/Embed/index.html?webmap=002dfbe5eeb040dea49672603473f8d3&extent=73.5861,15.2118,74.3043,15.5826&home=true&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=dark"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mapsection;
