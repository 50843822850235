import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import "./new-v.css";
// import Sidebar from './components/Sidebar';
import Home from "./components/home/Home";
import Prevention from "./components/prevention/Prevention";
import Donate from "./components/donate/Donate";
//import Events from "./components/events/Events.jsx";
import Team from "./components/team/Team.jsx";
import PWAPrompt from "react-ios-pwa-prompt";
//import Tribute from './components/temp-components/tribute.jsx';
import zenscroll from "zenscroll";

import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Footer from "./components/Footer";
import pray from "./pray.png";
import WhatsApp from "./WhatsApp.png";
import StatePage from "./components/statepage/StatePage.jsx";
import StateData from "./components/home/StateData.jsx";

// import logo from '.components/icons/logo.svg';

function checkForLightMode() {
  changeColorScheme();

  let logo = document.getElementsByClassName("logo-path");
  // console.log(logo[1]);

  for (let i = 0; i < logo.length; i++) {
    logo[i].style.fill = "#666";
    logo[i].style.stroke = "#666";
  }
}

function changeColorScheme() {
  let darkMode_bgColor = "#1E1D21";
  let darkMode_pTextColor = "#666666";
  let darkMode_titleColor = "#aaaaaa";
  let darkMode_layerColor = "#262529";
  let darkMode_liHover = "#2f2f33";
  let darkMode_sidebar = "#262529";

  let lightMode_bgColor = "#ebebeb";
  let lightMode_pTextColor = "#848484";
  let lightMode_titleColor = "#666666";
  let lightMode_layerColor = "#fcfcfc";
  let lightMode_liHover = "#ffffff";
  let lightMode_sidebar = "#f2f2f2";
  let root = getComputedStyle(document.documentElement);
  let prop = root.getPropertyValue("--bg-color");

  if (document.getElementById("iframeContainer")) {
    let frameContainer = document.getElementById("iframeContainer");
    frameContainer.removeChild(frameContainer.childNodes[0]);

    if (prop == lightMode_bgColor) {
      var newIframe = document.createElement("iframe");
      newIframe.src =
        "https://www.arcgis.com/apps/Embed/index.html?webmap=002dfbe5eeb040dea49672603473f8d3&extent=73.5861,15.2118,74.3043,15.5826&home=true&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=dark";
      frameContainer.appendChild(newIframe);
    } else {
      var newIframe = document.createElement("iframe");
      newIframe.src =
        "https://www.arcgis.com/apps/Embed/index.html?webmap=a212fb617e434dbc8cef5a6b2bd531c3&extent=73.5596,15.2204,74.2779,15.5911&home=true&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=light";
      frameContainer.appendChild(newIframe);
    }
  }

  if (prop == lightMode_bgColor) {
    document.documentElement.style.setProperty("--bg-color", darkMode_bgColor);
    document.documentElement.style.setProperty(
      "--p-text-color",
      darkMode_pTextColor
    );
    document.documentElement.style.setProperty(
      "--title-text-color",
      darkMode_titleColor
    );
    document.documentElement.style.setProperty(
      "--layer-color",
      darkMode_layerColor
    );
    document.documentElement.style.setProperty("--li-hover", darkMode_liHover);
    document.documentElement.style.setProperty(
      "--sidebar-color",
      darkMode_sidebar
    );
  } else {
    document.documentElement.style.setProperty("--bg-color", lightMode_bgColor);
    document.documentElement.style.setProperty(
      "--p-text-color",
      lightMode_pTextColor
    );
    document.documentElement.style.setProperty(
      "--title-text-color",
      lightMode_titleColor
    );
    document.documentElement.style.setProperty(
      "--layer-color",
      lightMode_layerColor
    );
    document.documentElement.style.setProperty("--li-hover", lightMode_liHover);
    document.documentElement.style.setProperty(
      "--sidebar-color",
      lightMode_sidebar
    );
  }
}

function navbarToggle() {
  let sidebar = document.getElementById("sidebar");
  let main = document.getElementById("main-content");
  let topBar = document.getElementById("topBar");
  // let blueBar = document.getElementsByClassName('notification')[0];

  let left = sidebar.style.left;

  if (window.innerWidth < 1025) {
    if (left == "0px") {
      sidebar.style.left = "-240px";
    } else {
      sidebar.style.left = "0px";
      main.style.paddingLeft = "15px";
    }
  } else {
    /*for bigger screens*/
    if (left == "-240px") {
      sidebar.style.left = "0px";
      // main.style.paddingTop = "75px";
      main.style.paddingTop = "25px";
      topBar.style.top = "-50px";
      main.style.paddingLeft = "270px";
      // blueBar.style.marginLeft = '240px';
    } else {
      sidebar.style.left = "-240px";
      main.style.paddingTop = "65px";
      topBar.style.top = "50px";
      main.style.paddingLeft = "30px";
      // blueBar.style.marginLeft = "0"
    }
  }
}

let shareAPI = () => {
  if (navigator.share) {
    navigator
      .share({
        title: "COVID-19 Tracker Goa",
        url: "https://goa.coronatracker.in",
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
  } else {
    console.log("support na re");
  }
};

// function closeNotification(){
//     document.getElementsByClassName('notification')[0].style.display = 'none';
//     let main = document.getElementById('main-content');

//     if (window.innerWidth < 1025) {
//         main.style.paddingTop = '75px';
//     }

//     else {
//         main.style.paddingTop = '25px'
//     }
// }

function navlinkClicked() {
  if (window.innerWidth < 1025) {
    navbarToggle();
  }
}

function goToTopClicked() {
  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth"
  // });
  zenscroll.toY(0);
}

var value = "corona";

class App extends Component {
  render() {
    return (
      //Div inside which the whole app gets rendered. It's CSS is in App.css
      <div className="App">
        <PWAPrompt
          timesToShow={5}
          delay={12000}
          permanentlyHideOnDismiss={false}
          copyBody="To install Goa.CoronaTracker.in as an app on your homescreen, follow the instructions below 👇"
        />
        {/* <Tribute /> */}
        <div className="banner_div">
          <p className="banner_div_text">
            🚨 Visit our main website{" "}
            <a href="https://www.coronatracker.in" target="_blank">
              <b>coronatracker.in</b>
            </a>{" "}
            for stats related to other Indian states
          </p>
        </div>
        <BrowserRouter>
          <div className="sidebar" id="sidebar">
            <div className="top-section">
              <div className="brand">
                <div className="brand__logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.709"
                    height="59.559"
                    viewBox="0 0 42.709 59.559"
                  >
                    <g
                      id="Logo"
                      transform="translate(-2.5 -2.5)"
                      opacity="0.41"
                    >
                      <path
                        id="Path_104"
                        className="logo-path"
                        data-name="Path 104"
                        d="M44.226,167.119c-3.338,10.351-12.373,19.793-15.73,23.047-3.359-3.256-12.4-12.71-15.738-23.07L8.72,167.08c4,14.267,17.816,26.581,18.5,27.182a1.932,1.932,0,0,0,2.553,0c.684-.6,14.476-12.9,18.488-27.148Z"
                        transform="translate(-4.643 -133.185)"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_105"
                        className="logo-path"
                        data-name="Path 105"
                        d="M7.278,28.035a22.1,22.1,0,0,1-.416-4.173,16.992,16.992,0,1,1,33.985,0,22.022,22.022,0,0,1-.407,4.124q1.96,0,3.92.008a25.552,25.552,0,0,0,.348-4.129A20.854,20.854,0,1,0,3,23.863a25.444,25.444,0,0,0,.36,4.19Z"
                        transform="translate(0 0)"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_106"
                        className="logo-path"
                        data-name="Path 106"
                        d="M80.064,86.12a7.867,7.867,0,1,0,7.814,7.867A7.85,7.85,0,0,0,80.064,86.12Zm0,12.608a4.742,4.742,0,1,1,4.689-4.741A4.72,4.72,0,0,1,80.064,98.728Z"
                        transform="translate(-56.211 -67.469)"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>

                <p className="brand__name">Corona Tracker - Goa</p>
              </div>
              <div className="sidebar__nav">
                <ul className="sidebar__nav-items-container">
                  <li>
                    <NavLink
                      exact
                      to="/"
                      className="sidebar__nav-items"
                      activeClassName="active"
                      onClick={navlinkClicked}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="scatter-plot"
                        width="22.793"
                        height="22.793"
                        viewBox="0 0 22.793 22.793"
                      >
                        <path
                          id="Path_112"
                          data-name="Path 112"
                          className="cls-1"
                          d="M22.716,36.561V24.452A4.452,4.452,0,0,1,27.167,20H24.452A4.452,4.452,0,0,0,20,24.452V36.561a4.452,4.452,0,0,0,4.452,4.452h2.716A4.452,4.452,0,0,1,22.716,36.561Z"
                          transform="translate(-19.11 -19.11)"
                        />
                        <path
                          id="Path_113"
                          data-name="Path 113"
                          className="cls-2"
                          d="M17.451,22.793H5.342A5.348,5.348,0,0,1,0,17.451V5.342A5.348,5.348,0,0,1,5.342,0H17.451a5.348,5.348,0,0,1,5.342,5.342V17.451A5.348,5.348,0,0,1,17.451,22.793ZM5.342,1.781A3.565,3.565,0,0,0,1.781,5.342V17.451a3.565,3.565,0,0,0,3.561,3.561H17.451a3.565,3.565,0,0,0,3.561-3.561V5.342a3.565,3.565,0,0,0-3.561-3.561Zm8.236,4.541a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,13.578,6.321ZM9.749,7.479a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,9.749,7.479Zm.224,6.993a1.113,1.113,0,1,0,1.019,1.019A1.113,1.113,0,0,0,9.973,14.472Zm-4.5,1.2a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,5.476,15.67Zm1.2-4.051a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,6.678,11.619ZM11.931,11a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,11.931,11Zm4.229-.979a1.113,1.113,0,1,0,1.113,1.113A1.113,1.113,0,0,0,16.16,10.016Z"
                        />
                        <circle
                          id="Ellipse_13"
                          data-name="Ellipse 13"
                          className="cls-3"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(16 4)"
                        />
                        <circle
                          id="Ellipse_15"
                          data-name="Ellipse 15"
                          className="cls-3"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 11)"
                        />
                      </svg>
                      <span>Home</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink
                      exact
                      to="events"
                      activeClassName="active"
                      className="sidebar__nav-items"
                      onClick={navlinkClicked}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.793"
                        height="23.924"
                        viewBox="0 0 100 100"
                        version="1.1"
                      >
                        <g id="surface467612">
                          <path
                            className="cls-2"
                            d="M 24 0 C 21.8125 0 20 1.8125 20 4 L 20 8 L 8 8 C 6.953125 8 5.890625 8.382812 5.140625 9.140625 C 4.382812 9.890625 4 10.953125 4 12 L 4 92 C 4 93.046875 4.382812 94.109375 5.140625 94.867188 C 5.890625 95.617188 6.953125 96 8 96 L 92 96 C 93.046875 96 94.109375 95.617188 94.867188 94.867188 C 95.617188 94.109375 96 93.046875 96 92 L 96 12 C 96 10.953125 95.617188 9.890625 94.867188 9.140625 C 94.109375 8.382812 93.046875 8 92 8 L 80 8 L 80 4 C 80 1.8125 78.1875 0 76 0 L 72 0 C 69.8125 0 68 1.8125 68 4 L 68 8 L 32 8 L 32 4 C 32 1.8125 30.1875 0 28 0 Z M 24 4 L 28 4 L 28 16 L 24 16 Z M 72 4 L 76 4 L 76 16 L 72 16 Z M 8 12 L 20 12 L 20 16 C 20 18.1875 21.8125 20 24 20 L 28 20 C 30.1875 20 32 18.1875 32 16 L 32 12 L 68 12 L 68 16 C 68 18.1875 69.8125 20 72 20 L 76 20 C 78.1875 20 80 18.1875 80 16 L 80 12 L 92 12 L 92 26 L 8 26 Z M 8 30 L 92 30 L 92 92 L 8 92 Z M 20 38 L 20 84 L 80 84 L 80 38 Z M 24 42 L 34 42 L 34 52 L 24 52 Z M 38 42 L 48 42 L 48 52 L 38 52 Z M 52 42 L 62 42 L 62 52 L 52 52 Z M 66 42 L 76 42 L 76 52 L 66 52 Z M 24 56 L 34 56 L 34 66 L 24 66 Z M 38 56 L 48 56 L 48 66 L 38 66 Z M 52 56 L 62 56 L 62 66 L 52 66 Z M 66 56 L 76 56 L 76 66 L 66 66 Z M 24 70 L 34 70 L 34 80 L 24 80 Z M 38 70 L 48 70 L 48 80 L 38 80 Z M 52 70 L 62 70 L 62 80 L 52 80 Z M 66 70 L 76 70 L 76 80 L 66 80 Z M 66 70 "
                          />
                        </g>
                      </svg>
  
                      <span>Live Events</span>
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink
                      exact
                      to="prevention"
                      activeClassName="active"
                      className="sidebar__nav-items"
                      onClick={navlinkClicked}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.793"
                        height="23.924"
                        viewBox="0 0 18.13 23.924"
                      >
                        <g id="invention" transform="translate(-62 0)">
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            className="cls-1"
                            d="M84.8,27.669a7.607,7.607,0,0,1,6.729-7.555,9.345,9.345,0,0,0-1.4-.108C85.638,20.017,82,23.174,82,27.669c0,5.584,5.747,9.719,5.747,9.719h4.019S84.8,33.253,84.8,27.669Z"
                            transform="translate(-19.065 -19.071)"
                          />
                          <path
                            id="Path_124"
                            data-name="Path 124"
                            className="cls-2"
                            d="M72.934,23.924H69.149a.935.935,0,0,1-.889-.648l-.467-1.449a.935.935,0,0,1,1.779-.574l.258.8h2.424l.258-.8a.935.935,0,1,1,1.779.574l-.467,1.449a.934.934,0,0,1-.889.648Zm1.01-4.847a18.363,18.363,0,0,0,3.071-2.927A11.96,11.96,0,0,0,80.13,8.6a8.314,8.314,0,0,0-2.691-6.209A9.393,9.393,0,0,0,71.108,0h-.087a9.393,9.393,0,0,0-6.33,2.388A8.314,8.314,0,0,0,62,8.6a12.009,12.009,0,0,0,3.089,7.55,18.277,18.277,0,0,0,3.047,2.927.934.934,0,0,0,.546.176H73.4A.935.935,0,0,0,73.944,19.078ZM63.869,8.6c0-3.829,3.092-6.718,7.2-6.729,4.1.011,7.2,2.9,7.2,6.729,0,4.426-4.148,7.974-5.175,8.785H69C67.975,16.567,63.869,13.021,63.869,8.6Z"
                            transform="translate(0 0)"
                          />
                          <path
                            id="Path_125"
                            data-name="Path 125"
                            className="cls-2"
                            d="M189.257,143.98a5.292,5.292,0,0,0-2.941-.979,5.47,5.47,0,0,0-2.941.979.935.935,0,1,0,1.115,1.5,3.726,3.726,0,0,1,.891-.443v4.973a.935.935,0,1,0,1.869,0v-4.973a3.726,3.726,0,0,1,.891.443.935.935,0,0,0,1.115-1.5Z"
                            transform="translate(-115.345 -136.319)"
                          />
                        </g>
                      </svg>
                      <span>Prevention Tips</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      exact
                      to="team"
                      activeClassName="active"
                      className="sidebar__nav-items"
                      onClick={navlinkClicked}
                    >
                      <svg viewBox="0 0 24 24" width="24px" height="24px">
                        <path
                          className="cls-2"
                          d="M 4.0097656 3 C 2.9179106 3 2.0097656 3.9049841 2.0097656 4.9980469 L 2 23 L 6 19 L 20 19 C 21.093063 19 22 18.093063 22 17 L 22 5 C 22 3.9069372 21.093063 3 20 3 L 4.0097656 3 z M 4.0097656 5 L 20 5 L 20 17 L 5.171875 17 L 4.0039062 18.167969 L 4.0097656 5 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 15 L 13 15 L 13 11 L 11 11 z"
                        />
                      </svg>

                      <span>Team</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bottom-section">
              <div className="preference">
                {/* <div className="preference__item">
                          <span className="preference__item__text">Enable Notifications</span>
                          <div className="preference__item__toggle">tog</div>
                      </div> */}

                <div className="preference__item">
                  <span className="preference__item__text">
                    Enable Light Mode
                  </span>
                  <label className="toggle" htmlFor="ligthmode">
                    <input
                      type="checkbox"
                      className="toggle__checkbox"
                      id="lightmode"
                      onClick={checkForLightMode}
                    />
                    <span className="toggle__slider"></span>
                  </label>
                </div>
              </div>

              <div className="feedback">
                <div className="feedback__title">
                  Help people in need <img src={pray} alt="icon"></img>
                </div>
                <Link to="/donate">
                  <div className="button donate">How to donate? </div>{" "}
                </Link>
              </div>

              <div className="feedback">
                {/* <div className="feedback__title">Your feedback matters</div> */}
                <a href="https://shreykeny.typeform.com/to/rUXsAb">
                  {" "}
                  <div className="button">Give feedback</div>{" "}
                </a>
                <div className="button button__share" onClick={shareAPI}>
                  Share the app
                </div>
              </div>
            </div>
          </div>
          <div className="topBar" id="topBar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42.709"
              height="59.559"
              viewBox="0 0 42.709 59.559"
              className="smallLogo"
            >
              <g id="Logo" transform="translate(-2.5 -2.5)" opacity="0.41">
                <path
                  id="Path_104"
                  className="logo-path"
                  data-name="Path 104"
                  d="M44.226,167.119c-3.338,10.351-12.373,19.793-15.73,23.047-3.359-3.256-12.4-12.71-15.738-23.07L8.72,167.08c4,14.267,17.816,26.581,18.5,27.182a1.932,1.932,0,0,0,2.553,0c.684-.6,14.476-12.9,18.488-27.148Z"
                  transform="translate(-4.643 -133.185)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
                <path
                  id="Path_105"
                  className="logo-path"
                  data-name="Path 105"
                  d="M7.278,28.035a22.1,22.1,0,0,1-.416-4.173,16.992,16.992,0,1,1,33.985,0,22.022,22.022,0,0,1-.407,4.124q1.96,0,3.92.008a25.552,25.552,0,0,0,.348-4.129A20.854,20.854,0,1,0,3,23.863a25.444,25.444,0,0,0,.36,4.19Z"
                  transform="translate(0 0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
                <path
                  id="Path_106"
                  className="logo-path"
                  data-name="Path 106"
                  d="M80.064,86.12a7.867,7.867,0,1,0,7.814,7.867A7.85,7.85,0,0,0,80.064,86.12Zm0,12.608a4.742,4.742,0,1,1,4.689-4.741A4.72,4.72,0,0,1,80.064,98.728Z"
                  transform="translate(-56.211 -67.469)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
            </svg>
            {/* <div className="button__share whatsapp-share-button"> */}
            {/* <a href="https://twitter.com/COVID19_INDIA"> <img src={Twitter} width="30px" /> </a> */}

            {/* <a href="https://bit.ly/CoronaTrackerWhatsAppShare">
                {" "}
                <img src={WhatsApp} width="30px" />{" "}
              </a> */}

            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="48px" height="48px"><path d="M 15.990234 1.9902344 A 1.0001 1.0001 0 0 0 15.292969 3.7070312 L 17.585938 6 L 17 6 C 10.936593 6 6 10.936593 6 17 A 1.0001 1.0001 0 1 0 8 17 C 8 12.017407 12.017407 8 17 8 L 17.585938 8 L 15.292969 10.292969 A 1.0001 1.0001 0 1 0 16.707031 11.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 20.707031 6.2929688 L 16.707031 2.2929688 A 1.0001 1.0001 0 0 0 15.990234 1.9902344 z M 2.984375 7.9863281 A 1.0001 1.0001 0 0 0 2 9 L 2 19 C 2 20.64497 3.3550302 22 5 22 L 19 22 C 20.64497 22 22 20.64497 22 19 L 22 18 A 1.0001 1.0001 0 1 0 20 18 L 20 19 C 20 19.56503 19.56503 20 19 20 L 5 20 C 4.4349698 20 4 19.56503 4 19 L 4 9 A 1.0001 1.0001 0 0 0 2.984375 7.9863281 z" /></svg> */}
            {/* </div> */}
            {/* <div className="notification">
                          <div className="notification__wrap">
                              <p className="notification__text">Stay Home. Stay Safe</p>
                              <div id="timer"></div>
                              <button className="notification__delete" onClick={closeNotification}>&#10006;</button>
                          </div>
                      </div> */}
          </div>

          <div className="menu-button" onClick={navbarToggle}>
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="96px"
              height="96px"
            >
              <path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z" />
            </svg>
          </div>

          <div className="main-content" id="main-content">
            <Switch>
              {/* <Route exact path="/" component={Home} /> */}
              <Home exact path="/" />
              <Route path="/prevention" component={Prevention} />
              <Route path="/donate" component={Donate} />
              {/* <Route path="/events" component={Events} /> */}
              <Route path="/team" component={Team} />
              <StatePage exact path="/state" />
              <Redirect to="/" />
            </Switch>
            <Footer />
          </div>
        </BrowserRouter>
        <div
          className="go-to-top-button"
          id="goTopButton"
          onClick={(e) => goToTopClicked()}
          style={{ opacity: 1, display: "none" }}
        >

          <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7077 10.6338V10.6338C20.0974 10.2289 20.0974 9.57338 19.7077 9.16951L11.4441 0.606572C10.6636 -0.202191 9.39747 -0.202191 8.61699 0.606572L0.29246 9.23165C-0.0932862 9.6324 -0.0982829 10.2796 0.282467 10.6856C0.671211 11.1008 1.31179 11.105 1.70653 10.6969L9.32352 2.80297C9.71426 2.39807 10.3468 2.39807 10.7376 2.80297L18.2946 10.6338C18.6844 11.0387 19.3179 11.0387 19.7077 10.6338" fill="#5851DB" />
          </svg>







        </div>
      </div>
    );
  }
}

export default App;
