import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
//import ScrollToTop from "../scrollToTop.jsx";
import Linechart from "./LineChart";
import LineChartDaily from "./LineChartDaily";
import buttonVisibility from "../buttonVisibility";
import MapSection from "./Mapsection";
import DistrictTable from "./districtTable";
import StatsContainer from "./statsContainer";
import WhatsAppShare from "./WhatsAppShare";
import TwitterAppShare from "./TwitterAppShare";
import FetchStateDailyData from "./fetchStateDailydata";
import FetchPlacesData from "./fetchPlacesData";
import PlacesTable from "./placesTable";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateDistricts: {},
      confirmed: 0,
      active: 0,
      recovered: 0,
      dead: 0,
      confirmedDaily: 0,
      activeDaily: 0,
      recoveredDaily: 0,
      deadDaily: 0,
      waText: "",
      twitterText: "",
      allDataLoaded: false,
      stateDailyData: {},
      placesData: [],
    };
  }

  districtData = () => {
    fetch("https://api.covid19india.org/state_district_wise.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let tmp = {};
        for (let dist in data["Goa"]["districtData"]) {
          tmp[dist] = data["Goa"]["districtData"][dist];
        }
        let stateDistricts = tmp;
        let totalConfirmed = 0,
          totalActive = 0,
          totalRecovered = 0,
          totalDead = 0;

        let deltaCnfrm = 0,
          deltaRcvd = 0,
          deltaDths = 0;

        for (let key in stateDistricts) {
          totalConfirmed += stateDistricts[key]["confirmed"];
          totalRecovered += stateDistricts[key]["recovered"];
          totalDead += stateDistricts[key]["deceased"];
          totalActive += stateDistricts[key]["active"];
          deltaCnfrm += stateDistricts[key]["delta"]["confirmed"];
          deltaRcvd += stateDistricts[key]["delta"]["recovered"];
          deltaDths += stateDistricts[key]["delta"]["deceased"];
        }

        let msg = `🚨 *COVID-19 updates for Goa* 🇮🇳 %0A%0A Active: ${totalActive}%0A%0A Recovered: ${totalRecovered}%0A%0A Deaths: ${totalDead}%0A%0A Total cases: ${totalConfirmed}%0A%0A`;
        // for(i=1; i<6; i++){
        //     msg += `${i}) *${info.statewise[i].state}*:%0A Active: ${info.statewise[i].active}%0A Recovered: ${info.statewise[i].recovered}%0A Deaths: ${info.statewise[i].deaths}%0A Total Cases: ${info.statewise[i].confirmed}%0A%0A`;
        // }

        let twitterText =
          "https://twitter.com/intent/tweet?text=" +
          `🚨 COVID 19 update for Goa %0A%0ATotal Active cases: ${totalActive}%0ATotal Recovered cases: ${totalRecovered}%0ATotal Deaths: ${totalDead}%0ATotal Confirmed cases: ${totalConfirmed}%0A%0A%0A%0A %23COVID19 %23IndiaFightsCorona 🦠%0A%0AShared via goa.coronatracker.in 📊 📈`;
        let waText =
          "https://api.whatsapp.com/send?text=" +
          msg +
          "Shared via *goa.coronatracker.in*  📊 🇮🇳";

        this.setState({
          confirmed: totalConfirmed,
          active: totalActive,
          recovered: totalRecovered,
          dead: totalDead,
          stateDistricts: stateDistricts,
          confirmedDaily: deltaCnfrm !== 0 ? "[+" + deltaCnfrm + "]" : "",
          activeDaily: "",
          recoveredDaily: deltaRcvd !== 0 ? "[+" + deltaRcvd + "]" : "",
          deadDaily: deltaDths !== 0 ? "[+" + deltaDths + "]" : "",
          sortCounter: 1,
          waText: waText,
          twitterText: twitterText,
          allDataLoaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  stateDailyData = async () => {
    let data = await FetchStateDailyData();
    let places = await FetchPlacesData();
    //console.log(data);
    //console.log("Goa: ", data["Goa"]);
    this.setState({
      stateDailyData: data["Goa"],
      placesData: places,
    });
  };

  componentDidMount() {
    this.districtData();
    this.stateDailyData();
    window.addEventListener("scroll", buttonVisibility);
  }

  componentWillMount() {
    window.removeEventListener("scroll", buttonVisibility);
  }

  render() {
    return (
      <div className="state-page" id="state-wise-data">
        <h2 class="state__title">
          Coronavirus Tracker for <strong>Goa</strong>
        </h2>

        <div className="map-stat-container">
          <StatsContainer stats={this.state} />
          <div className="share-module-container">
            <WhatsAppShare whatsappText={this.state.waText} />
            <TwitterAppShare twitterText={this.state.twitterText} />
          </div>
        </div>

        {/* map */}
        <br />
        <MapSection />

        <LineChartDaily
          status={"India"}
          stateDailyData={this.state.stateDailyData}
          worldTimelineData={null}
        />

        <Linechart
          status={"India"}
          stateDailyData={this.state.stateDailyData}
          worldTimelineData={null}
        />

        <DistrictTable stateDistricts={this.state.stateDistricts} />

        <PlacesTable places={this.state.placesData} />
        {/* <ScrollToTop /> */}


      </div>
    );
  }
}

export default Home;
