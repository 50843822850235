import React, { Component } from "react";

class PlacesTable extends Component {
  constructor(props) {
    super(props);
  }

  addToTable = (elements) => {
    let tab = document.getElementById("places-table-body");

    while (tab.firstChild) {
      tab.removeChild(tab.firstChild);
    }

    let rank = 1;
    for (let element of elements) {
      //console.log(element);
      let tr = document.createElement("tr");
      //let td0 = document.createElement('td');
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      // let td3 = document.createElement("td");
      // let td4 = document.createElement("td");
      // let td5 = document.createElement("td");

      //td0.innerHTML = rank.toString();
      td1.innerHTML = element.place;
      td2.innerHTML = element.active
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // td3.innerHTML =
      //   elements[element]["active"] < 0
      //     ? 0
      //     : elements[element]["active"]
      //         .toString()
      //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // td4.innerHTML = elements[element]["recovered"]
      //   .toString()
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // td5.innerHTML = element.active
      //   .toString()
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //tr.appendChild(td0);
      tr.appendChild(td1);
      tr.appendChild(td2);
      // tr.appendChild(td3);
      // tr.appendChild(td4);
      // tr.appendChild(td5);

      tab.appendChild(tr);
      rank += 1;
    }
  };

  searchPlaces() {
    //console.log("Hello");
    let input = document.getElementById("placesInput").value;

    let clearBtn = document.getElementById("clearBtn");

    if (input === "") {
      clearBtn.style.display = "none";
    } else {
      clearBtn.style.display = "block";
    }

    let filter = input.toUpperCase();
    let table = document.getElementById("placesTable");
    let tr = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      let place = tr[i].getElementsByTagName("td")[0];
      if (place) {
        let txt = place.innerText;
        if (txt.toUpperCase().indexOf(filter) !== -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  clearBtnPressed = () => {
    document.getElementById("placesInput").value = "";
    this.searchPlaces();
    document.getElementById("clearBtn").style.display = "none";
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.places !== this.props.places) {
      return true;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.addToTable(
        this.props.places.sort(function (a, b) {
          return b.active - a.active;
        })
      );
    }
  }

  render() {
    return (
      <div className="state state-wise-data places">
        <h3 className="state__title"> Location of Health Centres in Goa</h3>
        {/* <div className="state__sort-button" onClick={this.sortClicked}>
              Sorted:{" "}
              {
                this.state.sortTypeArr[
                  this.state.sortCounter === 0 ? 4 : this.state.sortCounter - 1
                ]
              }
            </div> */}

        <div className="search-container">
          <input
            type="text"
            id="placesInput"
            className="search-stats-states flex-item-search"
            onKeyUp={this.searchPlaces}
            placeholder="Search"
          ></input>
          {/* <button className="flex-item-button"/> */}
          <span
            id="clearBtn"
            className="flex-item-span"
            style={{ display: "none" }}
            onClick={this.clearBtnPressed}
          >
            <button className="cleanBtn">✖️</button>
          </span>
        </div>
        <div className="state__table-container">
          <table id="placesTable">
            <thead>
              <tr>
                <th>Place</th>
                <th>Actve</th>
              </tr>
            </thead>

            <tbody id="places-table-body"></tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default PlacesTable;
