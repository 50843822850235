import React, { Component } from "react";
import RecoveredChartGoa from "./RecoveredChartGoa";
import RecoveredChartWorld from "./RecoveredChartWorld";
import InfectedChartGoa from "./InfectedChartGoa";
import InfectedChartWorld from "./InfectedChartWorld";
import DeathChartGoa from "./DeathChartGoa";
import DeathChartWorld from "./DeathChartWorld";
import ActiveChartGoa from "./ActiveChartGoa";

let Linechart = (props) => {
  function dropOptions() {
    let dropdown = document.getElementById("toggleDropdown");

    if (dropdown.style.display == "block") {
      dropdown.style.display = "none";
    } else {
      dropdown.style.display = "block";
    }
  }

  function changeToRecovered() {
    let tag = document.getElementById("var-tag");
    let label = document.getElementById("var-label");
    let title = document.getElementById("var-title");
    let recoveredChart = document.getElementsByClassName("recovered-chart");
    let infectedChart = document.getElementsByClassName("infected-chart");
    let deathChart = document.getElementsByClassName("death-chart");
    let activeChart = document.getElementsByClassName("active-chart")[0];

    label.innerText = "Recovered";
    tag.style.backgroundColor = "#4ccf76";
    title.innerText = "Total Recovered from Coronavirus";

    for (let i = 0; i < 2; i++) {
      infectedChart[i].style.display = "none";
      recoveredChart[i].style.display = "block";
      deathChart[i].style.display = "none";
      //activeChart[i].style.display = "none";
    }
    activeChart.style.display = "none";

    dropOptions();
  }

  function changeToDeath() {
    let tag = document.getElementById("var-tag");
    let label = document.getElementById("var-label");
    let title = document.getElementById("var-title");
    let recoveredChart = document.getElementsByClassName("recovered-chart");
    let infectedChart = document.getElementsByClassName("infected-chart");
    let deathChart = document.getElementsByClassName("death-chart");
    let activeChart = document.getElementsByClassName("active-chart")[0];

    label.innerText = "Death";
    tag.style.backgroundColor = "#e1306c";
    title.innerText = "Total Dead from Coronavirus";
    for (let i = 0; i < 2; i++) {
      infectedChart[i].style.display = "none";
      recoveredChart[i].style.display = "none";
      deathChart[i].style.display = "block";
      //activeChart[i].style.display = "none";
    }
    activeChart.style.display = "none";
    dropOptions();
  }

  function changeToInfected() {
    let tag = document.getElementById("var-tag");
    let label = document.getElementById("var-label");
    let title = document.getElementById("var-title");
    let recoveredChart = document.getElementsByClassName("recovered-chart");
    let infectedChart = document.getElementsByClassName("infected-chart");
    let deathChart = document.getElementsByClassName("death-chart");
    let activeChart = document.getElementsByClassName("active-chart")[0];

    label.innerText = "Confirmed";
    tag.style.backgroundColor = "#5851db";
    title.innerText = "Total Confirmed with Coronavirus";
    for (let i = 0; i < 2; i++) {
      infectedChart[i].style.display = "block";
      recoveredChart[i].style.display = "none";
      deathChart[i].style.display = "none";
      //activeChart[i].style.display = "none";
    }
    activeChart.style.display = "none";
    dropOptions();
  }

  function changeToActive() {
    let tag = document.getElementById("var-tag");
    let label = document.getElementById("var-label");
    let title = document.getElementById("var-title");
    let recoveredChart = document.getElementsByClassName("recovered-chart");
    let infectedChart = document.getElementsByClassName("infected-chart");
    let deathChart = document.getElementsByClassName("death-chart");
    let activeChart = document.getElementsByClassName("active-chart")[0];

    label.innerText = "Active";
    tag.style.backgroundColor = "rgb(0, 123, 255)";
    title.innerText = "Total Active with Coronavirus";
    for (let i = 0; i < 2; i++) {
      //activeChart[i].style.display = "block";
      infectedChart[i].style.display = "none";
      recoveredChart[i].style.display = "none";
      deathChart[i].style.display = "none";
    }
    activeChart.style.display = "block";
    dropOptions();
  }

  return (
    <div className="line-chart">
      {/* <div className="detail-bar">
                <div className="detail-bar__title-container">
                    <h2 className="detail-bar__title">Coronavirus Statistics in </h2>
                </div>
            </div> */}

      <div className="chart">
        {/* <div className="chart__message">
                    <p>Click on the points to expand details</p>
                </div> */}

        <div className="main-toggle">
          <ul onClick={dropOptions}>
            <li className="main-toggle__el">
              <div className="main-toggle__tag" id="var-tag"></div>
              <p id="var-label">Confirmed</p>
            </li>
          </ul>

          <ul className="main-toggle__el-container" id="toggleDropdown">
            <li className="main-toggle__el" onClick={changeToInfected}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#5851db" }}
              ></div>
              <p>Confirmed</p>{" "}
              {/* <p>Infected</p> - Infected / Confirmed mean the same */}
            </li>

            <li className="main-toggle__el" onClick={changeToActive}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "rgb(0, 123, 255)" }}
              ></div>
              <p>Active</p>
            </li>

            <li className="main-toggle__el" onClick={changeToRecovered}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#4ccf76" }}
              ></div>
              <p>Recovered</p>
            </li>

            <li className="main-toggle__el" onClick={changeToDeath}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#e1306c" }}
              ></div>
              <p>Death</p>
            </li>
          </ul>
        </div>

        <div className="chart__block">
          <p className="chart__title" id="var-title">
            Total Confirmed with Coronavirus
          </p>
          <div id="national-data">
            <RecoveredChartGoa stateDailyData={props.stateDailyData} />
            <InfectedChartGoa stateDailyData={props.stateDailyData} />
            <DeathChartGoa stateDailyData={props.stateDailyData} />
            <ActiveChartGoa stateDailyData={props.stateDailyData} />
          </div>

          <div id="global-data">
            <RecoveredChartWorld worldTimelineData={props.worldTimelineData} />
            <InfectedChartWorld worldTimelineData={props.worldTimelineData} />
            <DeathChartWorld worldTimelineData={props.worldTimelineData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Linechart;
