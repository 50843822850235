function getDate(a) {
  //let a = "3/26/2020";
  let trimmed = a.split("-");
  let dateNew = trimmed[2];
  //console.log(trimmed[1]);
  switch (trimmed[1]) {
    case "01":
    case "1":
      dateNew += " January";
      break;
    case "02":
    case "2":
      dateNew += " February";
      break;
    case "03":
    case "3":
      dateNew += " March";
      break;
    case "04":
    case "4":
      dateNew += " April";
      break;
    case "05":
    case "5":
      dateNew += " May";
      break;
    case "06":
    case "6":
      dateNew += " June";
      break;
    case "07":
    case "7":
      dateNew += " July";
      break;
    case "08":
    case "8":
      dateNew += " August";
      break;
    case "09":
    case "9":
      dateNew += " September";
      break;
    case "10":
      dateNew += " October";
      break;
    case "11":
      dateNew += " November";
      break;
    case "12":
      dateNew += " December";
      break;
    default:
      break;
  }
  //console.log(dateNew);
  return dateNew;
}

export default getDate;
