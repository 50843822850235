import React, { Component } from "react";
import RecoveredChartGoaDaily from "./RecoveredChartGoaDaily";
//import RecoveredChartWorld from './RecoveredChartWorld';
import InfectedChartGoaDaily from "./InfectedChartGoaDaily";
//import InfectedChartWorld from './InfectedChartWorld';
import DeathChartGoaDaily from "./DeathChartGoaDaily";
//import DeathChartWorld from './DeathChartWorld';
import ActiveChartGoaDaily from "./ActiveChartGoaDaily";

let LinechartDaily = (props) => {
  function dropOptions() {
    let dropdown = document.getElementById("toggleDropdownDaily");

    if (dropdown.style.display == "block") {
      dropdown.style.display = "none";
    } else {
      dropdown.style.display = "block";
    }
  }

  function changeToRecoveredDaily() {
    let tag = document.getElementById("var-tag-daily");
    let label = document.getElementById("var-label-daily");
    let title = document.getElementById("var-title-daily");

    let recoveredChart = document.getElementById("rcvr-cht");
    let infectedChart = document.getElementById("inf-cht");
    let deathChart = document.getElementById("dth-cht");
    let activeChart = document.getElementById("actv-cht");

    label.innerText = "Recovered";
    tag.style.backgroundColor = "#4ccf76";
    title.innerText = "Daily Recovered from Coronavirus";

    infectedChart.style.display = "none";
    recoveredChart.style.display = "block";
    deathChart.style.display = "none";
    activeChart.style.display = "none";

    dropOptions();
  }

  function changeToDeathDaily() {
    let tag = document.getElementById("var-tag-daily");
    let label = document.getElementById("var-label-daily");
    let title = document.getElementById("var-title-daily");

    let recoveredChart = document.getElementById("rcvr-cht");
    let infectedChart = document.getElementById("inf-cht");
    let deathChart = document.getElementById("dth-cht");
    let activeChart = document.getElementById("actv-cht");

    label.innerText = "Death";
    tag.style.backgroundColor = "#e1306c";
    title.innerText = "Daily Dead from Coronavirus";

    infectedChart.style.display = "none";
    recoveredChart.style.display = "none";
    deathChart.style.display = "block";
    activeChart.style.display = "none";

    dropOptions();
  }

  function changeToInfectedDaily() {
    let tag = document.getElementById("var-tag-daily");
    let label = document.getElementById("var-label-daily");
    let title = document.getElementById("var-title-daily");

    let recoveredChart = document.getElementById("rcvr-cht");
    let infectedChart = document.getElementById("inf-cht");
    let deathChart = document.getElementById("dth-cht");
    let activeChart = document.getElementById("actv-cht");

    label.innerText = "Confirmed";
    tag.style.backgroundColor = "#5851db";
    title.innerText = "Daily Confirmed with Coronavirus";

    infectedChart.style.display = "block";
    recoveredChart.style.display = "none";
    deathChart.style.display = "none";
    activeChart.style.display = "none";

    dropOptions();
  }

  function changeToActiveDaily() {
    let tag = document.getElementById("var-tag-daily");
    let label = document.getElementById("var-label-daily");
    let title = document.getElementById("var-title-daily");

    let recoveredChart = document.getElementById("rcvr-cht");
    let infectedChart = document.getElementById("inf-cht");
    let deathChart = document.getElementById("dth-cht");
    let activeChart = document.getElementById("actv-cht");

    label.innerText = "Active";
    tag.style.backgroundColor = "rgb(0, 123, 255)";
    let styl = "font-size:12px;";
    title.innerHTML = `Daily Active with Coronavirus*<br><span style=${styl}>(-ve signifies a fall in <br/>total active cases)</span>`;

    infectedChart.style.display = "none";
    recoveredChart.style.display = "none";
    deathChart.style.display = "none";
    activeChart.style.display = "block";

    dropOptions();
  }

  return (
    <div className="line-chart">
      <div className="chart">
        <div className="main-toggle">
          <ul onClick={dropOptions}>
            <li className="main-toggle__el">
              <div className="main-toggle__tag" id="var-tag-daily"></div>
              <p id="var-label-daily">Confirmed</p>
            </li>
          </ul>

          <ul className="main-toggle__el-container" id="toggleDropdownDaily">
            <li className="main-toggle__el" onClick={changeToInfectedDaily}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#5851db" }}
              ></div>
              <p>Confirmed</p>{" "}
              {/* <p>Infected</p> - Infected / Confirmed mean the same */}
            </li>

            <li className="main-toggle__el" onClick={changeToActiveDaily}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "rgb(0, 123, 255)" }}
              ></div>
              <p>Active</p>{" "}
              {/* <p>Infected</p> - Infected / Confirmed mean the same */}
            </li>

            <li className="main-toggle__el" onClick={changeToRecoveredDaily}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#4ccf76" }}
              ></div>
              <p>Recovered</p>
            </li>

            <li className="main-toggle__el" onClick={changeToDeathDaily}>
              <div
                className="main-toggle__tag"
                style={{ backgroundColor: "#e1306c" }}
              ></div>
              <p>Death</p>
            </li>
          </ul>
        </div>

        <div className="chart__block">
          <p className="chart__title" id="var-title-daily">
            Daily Confirmed with Coronavirus
          </p>
          <div id="national-data-daily">
            <RecoveredChartGoaDaily stateDailyData={props.stateDailyData} />
            <InfectedChartGoaDaily stateDailyData={props.stateDailyData} />
            <DeathChartGoaDaily stateDailyData={props.stateDailyData} />
            <ActiveChartGoaDaily stateDailyData={props.stateDailyData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinechartDaily;
