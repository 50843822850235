let fetchPlaces = async () => {
  let places = [];
  await fetch(
    "https://script.googleusercontent.com/macros/echo?user_content_key=wguZF-hPaA-FEGd5ArBTfAYg1fKLK-wuw08bZOPqUV1Yho53MK8mfZyTLmApLj-zQWDm_504_cglsMTpCK9H_JNmx3lJyfhEm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnPghEfdy6oXl3iHIsa_WxIWHQAnXHR-hXBXaOzwDHFh4Y4TSGI0KiBTTI30SMbYjYTNiypj3wrxwLthsSg96B3c&lib=MXjOEtw_clwNfZkiU61zRXFmwiqQBXdaz"
  )
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      data.data.forEach((item) => {
        if (item.Confirmed !== "") {
          places.push({
            place: item.Place,
            active: item.Confirmed,
          });
        }
      });
    })
    .catch((err) => console.log(err));
  return places;
  //console.log(places);
};

export default fetchPlaces;
