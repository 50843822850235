import React, { Component } from "react";

import Netlify from "./full-logo-dark.png";

let Footer = () => {
  return (
    <div>
      <footer>
        {/* <p>Designed & developed by <a href="https://twitter.com/NiketKamat">Niket</a>, <a href="https://twitter.com/ShreyKeny">Shrey</a>, <a href="https://www.instagram.com/sprintly_aura24/">Hrishikesh</a>, <a href="https://www.instagram.com/sanket_marathe_09/">Sanket</a>, <a href="https://twitter.com/niche_nt">Nishant</a> and <a href="https://twitter.com/__salil_naik__">Salil Naik</a></p> */}

        <p>
          Source :{" "}
          <a href="https://www.goa.gov.in/covid-19/" target="_blank">
            Government of Goa
          </a>
          ,{" "}
          <a href="https://www.mohfw.gov.in/" target="_blank">
            MOHFW
          </a>{" "}
          &{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSc_2y5N0I67wDU38DjDh35IZSIS30rQf7_NYZhtYYGU1jJYT6_kDx4YpF-qw0LSlGsBYP8pqM_a1Pd/pubhtml#"
            target="_blank"
          >
            Crowd sourced with verification
          </a>{" "}
        </p>

        <p>
          {" "}
          Thanks to
          <a href="https://netlify.com" target="_blank">
            {" "}
            <img width="80" src={Netlify} />{" "}
          </a>
          for supporting us{" "}
        </p>
      </footer>
    </div>
  );
};

export default Footer;
