import React, { Component } from "react";
import {Event, initGA} from '../ga-tracking';

class TwitterAppShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twitterText: "",
    };
  }
  componentDidMount(){
    initGA('UA-158859238-1', {titleCase: false});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.twitterText !== "" ||
        nextProps.twitterText !== null ||
        nextProps.twitterText !== undefined) &&
      document.getElementById("twitterAppDataShareButton") !== null
    ) {
      document.getElementById("twitterAppDataShareButton").href =
        nextProps.twitterText;
      return {
        twitterText: nextProps.twitterText,
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="share-module">
        <div className="share-module__title">
          Share Covid-19 update on 
        </div>
        <a
          id="twitterAppDataShareButton"
          href="#"
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          <div
            className={`button-location ${
              this.state.twitterText === "" ? "loading" : ""
            }`} style={{backgroundColor:'#1DA1F2'}}
            // onClick={() => {
            //   Event('TwitterButton', 'ClickTwitter', 'TwitterUpdatesShare');
            // }}
          >
            Twitter
          </div>
        </a>

        {/* <a id="twitterAppDataShareButton" href="#"><div className="button-location">Share On twitter</div></a> */}
      </div>
    );
  }
}

export default TwitterAppShare;
