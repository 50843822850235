import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { findByLabelText } from "@testing-library/react";
import StatePage from "../statepage/StatePage";
import { Redirect, Route } from "react-router-dom";

class StateData extends Component {
  constructor(props) {
    super();
    //state added to prevent multiple updates to the table
    this.state = {
      dataPushed: false,
      stateName: null,
      districts: null,
      currentState: null,
      stateDailyDelta: null,
      delta: null,
    };
  }

  resetState = () => {
    this.setState({
      stateName: null,
      currentState: null,
      delta: null,
    });
  };

  componentDidUpdate(nextProps) {
    const stateData = {
      stateName: this.state.stateName,
      currentState: this.state.currentState,
      resetState: this.resetState,
      stateDelta: this.state.delta,
    };

    let rank = 1;

    let changeToState = (td) => {
      //console.log(td);
      let delta;
      this.state.stateDailyDelta.forEach((element) => {
        //console.log(element['state']);
        if (element["state"] === td) {
          delta = element;
        }
      });

      //console.log(delta);

      this.setState({
        stateName: td,
        currentState: this.state.districts[td],
        delta: delta,
      });
    };

    let addToTable = (element) => {
      if (element.state != "Total" && element.state !== "State Unassigned") {
        let tab = document.getElementById("table-body");
        let tr = document.createElement("tr");
        //let td0 = document.createElement('td');
        let td1 = document.createElement("td");
        let td2 = document.createElement("td");
        let td3 = document.createElement("td");
        let td4 = document.createElement("td");

        // let div0 = document.createElement('div');
        // div0.setAttribute("style", "display:flex;justify-content:space-between;");
        // let rankDiv = document.createElement("div");
        // rankDiv.innerHTML=rank;
        // div0.appendChild(rankDiv);

        let buttonDiv = document.createElement("div");
        buttonDiv.setAttribute("id", `${element.state}`);
        buttonDiv.setAttribute("class", "state-button-div noSelect");
        buttonDiv.onclick = function () {
          changeToState(this.id);
        };
        let btnImg = document.createElement("img");
        btnImg.setAttribute(
          "src",
          "https://cdn1.iconfinder.com/data/icons/mix-ui/24/Up_Top_Arrow_Arrows_Back_Direction-512.png"
        );
        btnImg.setAttribute("width", "12px");
        btnImg.setAttribute("height", "12px");
        //btnImg.setAttribute("style", "")
        btnImg.setAttribute("class", "state-button");
        buttonDiv.appendChild(btnImg);

        let div1 = document.createElement("div");
        div1.setAttribute(
          "style",
          "display:flex; justify-content: flex-start; align-items: center;"
        );
        let stateDiv = document.createElement("div");
        
        div1.appendChild(buttonDiv);
        stateDiv.innerHTML = element.state;
        div1.appendChild(stateDiv);

        //div0.appendChild(buttonDiv);

        //td0.appendChild(div0);
        //td0.innerHTML = `<div style="display:flex;justify-content:space-between;"><div>${rank}</div><div id=${element.state + "_div"}><img id=${element.state} src="https://cdn1.iconfinder.com/data/icons/mix-ui/24/Up_Top_Arrow_Arrows_Back_Direction-512.png" width="20px"/></div></div>`;
        td1.appendChild(div1);
        //td1.innerHTML = element.state;
        td2.innerHTML = element.confirmed
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        td3.innerHTML = element.recovered
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        td4.innerHTML = element.deaths
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // console.log(element)

        // td1.onclick = function() {
        //     changeToState(this);
        // }
        //function(){
        //console.log(this);
        //this.setState({stateName: this.innerHTML});
        //console.log(this.state);
        //}

        tr.setAttribute("class", element.state);
        tr.onclick = function() {
          changeToState(this.className);
        }
        tr.setAttribute("style", "cursor: pointer;");
        //tr.appendChild(td0);
        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.appendChild(td3);
        tr.appendChild(td4);

        tab.appendChild(tr);

        rank += 1;
      }
    };

    let states = nextProps.indiaRawData;
    let districts = nextProps.districtData;
    let dailyDelta = nextProps.stateDailyDelta;

    //console.log(districts);

    if (
      (states != null || states != undefined) &&
      (districts != null || districts != undefined) &&
      (dailyDelta != null || dailyDelta != undefined) &&
      !this.state.dataPushed
    ) {
      states.statewise.forEach(addToTable);
      this.setState({
        dataPushed: true,
        districts: districts,
        stateName: null,
        stateDailyDelta: dailyDelta,
      }); //sets new state
    }
  }

  searchStates() {
    //console.log("Hello");
    let input = document.getElementById("stateInput").value;

    let clearBtn = document.getElementById("clearBtn");

    if (input === "") {
      clearBtn.style.display = "none";
    } else {
      clearBtn.style.display = "block";
    }

    let filter = input.toUpperCase();
    let table = document.getElementById("stateTable");
    let tr = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      let state = tr[i].getElementsByTagName("td")[0];
      if (state) {
        let txt = state.innerText;
        if (txt.toUpperCase().indexOf(filter) !== -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  clearBtnPressed = () => {
    document.getElementById("stateInput").value = "";
    this.searchStates();
    document.getElementById("clearBtn").style.display = "none";
  };

  render() {
    if (this.state.stateName !== null) {
      window.history.pushState({}, '', '/');
      return (
        <Redirect
          to={{
            pathname: "/state",
            data: {
              stateName: this.state.stateName,
              currentState: this.state.currentState,
              resetState: this.resetState,
              stateDelta: this.state.delta,
            },
          }}
        />
      );
    }

    return (
      <div id="national-state-data" className="state state-wise-data">
        <h3 className="state__title">State wise statistics of India</h3>
        <div className="search-container">
          <input
            type="text"
            id="stateInput"
            className="search-stats-states flex-item-search"
            onKeyUp={this.searchStates}
            placeholder="Search State"
          ></input>
          {/* <button className="flex-item-button"/> */}
          <span
            id="clearBtn"
            className="flex-item-span"
            style={{ display: "none" }}
            onClick={this.clearBtnPressed}
          >
            <button className="cleanBtn">✖️</button>
          </span>
        </div>
        <div className="state__table-container">
          <table id="stateTable">
            <thead>
              <tr>
                {/* <th>Rank</th> */}
                <th>District</th>
                <th>Cnfrm</th>
                <th>Rcvrd</th>
                <th>Dead</th>
              </tr>
            </thead>

            <tbody id="table-body"></tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default StateData;
